import './Homepage.css';
import {useState} from "react";
import ReactJson from "react-json-view";
import EmployeeQuery from "./employee_query";

const GET_ORGANIZATION_CHART = "GET@/organization-chart";
const GET_ME = "GET@/me";
const POST_ORGANIZATION_CHART = "POST@/organization-chart";
const POST_EMPLOYEE = "POST@/employee";

const apiBodyStore = {
    [GET_ORGANIZATION_CHART]: "",
    [GET_ME]: "",
    [POST_ORGANIZATION_CHART]: "",
    [POST_EMPLOYEE]: "",
}

const AnnieHomepage = () => {
    const [apiPath, setApiPath] = useState(GET_ORGANIZATION_CHART);
    const [apiBody, setApiBody] = useState("");
    const [apiComponent, setApiComponent] = useState();
    const [inputComponent, setInputComponent] = useState();

    const handleApiBodyUpdate = (event) => {
        setApiBody(JSON.stringify({"organizationData": event.target.value.replace("\n", "\\n")}));
    }

    const handleRouteSelect = (event) => {
        apiBodyStore[apiPath] = apiBody;
        const value = event.target.value;

        if (value === GET_ORGANIZATION_CHART || value === GET_ME) {
            setInputComponent(null);
        }

        if (value === POST_ORGANIZATION_CHART) {
            setInputComponent(<textarea className="CSVTextArea" onChange={handleApiBodyUpdate}/>);
        }

        if (value === POST_EMPLOYEE) {
            setInputComponent(<EmployeeQuery bodySetter={setApiBody}/>)
        }

        setApiPath(value);
        let changeValue = apiBodyStore[value];
        changeValue = changeValue === undefined ? "" : changeValue;

        setApiBody(changeValue);
    }

    const handleApiSubmit = async (event) => {
        event.preventDefault();
        const parsedValue = apiPath.split("@");
        const httpMethod = parsedValue[0];
        const endpoint = parsedValue[1];

        const init = {method: httpMethod}
        if (httpMethod !== "GET") init.body = apiBody;
        console.log(init.body);

        const json = await fetch(endpoint, init).then(resp => resp.json());
        setApiComponent(
            <ReactJson className="JsonDisplay"
                       src={json} name={null}
                       collapsed={2}
                       indentWidth={8}
                       displayDataTypes={false}
                       enableClipboard={false}
                       theme={"summerfruit:inverted"}
            />
        );
    }

    return (
        <div className="Homepage">
            <h1>🌸&nbsp; Annie Hu &nbsp;🌸️</h1>

            <p className="WelcomeInfo">
                <b>Welcome to my Cloudflare General Assignment!</b>
                <br/>
                For convenience, you can use get/post requests on this homepage!
                <br/>
                You can still use traditional url
                navigation for the get requests (e.g. <a href="https://anniecloudflare.pages.dev/organization-chart">anniecloudflare.pages.dev/organization-chart</a> and <a href="https://anniecloudflare.pages.dev/me">anniecloudflare.pages.dev/me</a>).
                <br/>
                <br/>
                You can also navigate to <a href="https://anniecloudflare.pages.dev/orgchart">anniecloudflare.pages.dev/orgchart</a> to view the UI I created using the <a href="https://github.com/dabeng/react-orgchart">@dabeng/react-orgchart library</a>.
                <br/>
                For this UI, you can click to expand/collapse a node and drag/scroll to view the tree.
                <br/>
                <br/>
                For POST /organization-chart on this homepage, you only need to input the CSV file as a string.
                <br/>
                For your convenience, you do not need to input it in the format of &#123; &quot;organizationData&quot;: string&#125;.
            </p>

            <h3 style={{paddingTop: "10px"}}>💮&nbsp; About Me &nbsp;💮</h3>

            <p className="AboutMe">
                Hi! I'm Annie and I'm a first-year Turing Scholar (computer science honors) student at UT Austin.
                <br/>
                See my other projects on my <a href="https://github.com/anniehu17">Github</a> and feel free to connect with
                me on <a href="https://www.linkedin.com/in/annie-grace-hu/">LinkedIn</a>!
                <br/>
                You can also view my <a href="https://utexas.box.com/s/w3fqfpi9eox73jsyx092wsvnkchk9wwy">resume</a> to learn more about my skills and experience!
            </p>

            <h3>✨ Try Out An API ✨</h3>
            <form className="SelectRoute" onSubmit={handleApiSubmit}>
                <select value={apiPath} onChange={handleRouteSelect}>
                    <option value={GET_ORGANIZATION_CHART}>GET /organization-chart</option>
                    <option value={GET_ME}>GET /me</option>
                    <option value={POST_ORGANIZATION_CHART}>POST /organization-chart</option>
                    <option value={POST_EMPLOYEE}>POST /employee</option>
                </select>
                <input value="Submit" type="submit"/>
                <div className="InputArea">
                    {inputComponent}
                </div>
            </form>

            <div>{apiComponent}</div>
        </div>
    );
}

export default AnnieHomepage;