import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AnnieOrgChart from "./annie_orgchart";
import AnnieHomepage from "./annie_homepage";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <link href='https://fonts.googleapis.com/css?family=Trocchi' rel='stylesheet'/>
        <link href='https://fonts.googleapis.com/css?family=Comfortaa' rel='stylesheet'/>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<AnnieHomepage/>}/>
                <Route path="/orgchart" element={<AnnieOrgChart/>}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);