import {useState} from "react";

const queryBody = {}
let defaultDepartment = "Any";

const EmployeeQuery = ({bodySetter}) => {
    const [useCustomDepartmentQuery, setUseCustomDepartmentQuery] = useState(queryBody.defaultDepartment === "Custom");

    bodySetter("{}");

    const QueryBodyUpdater = (field) => {
        return (event) => {
            const value = event.target.value;
            if (value === "" || value === undefined || value === null) {
                delete queryBody[field];
            }
            else queryBody[field] = value;
            bodySetter(JSON.stringify(queryBody));
        }
    }

    const handleDepartmentSelection = (event) => {
        const value = event.target.value;
        defaultDepartment = value;
        if (value === "Custom") {
            event = {target: {value: ""}}
        }
        setUseCustomDepartmentQuery(event.target.value === "");
        QueryBodyUpdater("department")(event)
    }

    return (
        <div className="EmployeeQuery">
            <div className="QueryElement">
                <label>
                    <p>Name</p>
                    <input value={queryBody.name} type="text" onChange={QueryBodyUpdater("name")}/>
                </label>
            </div>
            <div className="QueryElement">
                <label>
                    <p>Department</p>
                    <select className="DepartmentSelector" defaultValue={defaultDepartment} onChange={handleDepartmentSelection}>
                        <optgroup>
                            <option value={undefined}>Any</option>
                            <option value="CDN">CDN</option>
                            <option value="Developer Platform">Developer Platform</option>
                            <option value="Accounting">Accounting</option>
                            <option value="Bots">Bots</option>
                            <option value="Custom">Custom</option>
                        </optgroup>
                    </select>
                    {useCustomDepartmentQuery && (
                        <input className="CustomDepartmentQuery" value={queryBody.department}
                               onChange={QueryBodyUpdater("department")} type="text"/>)}
                </label>
            </div>
            <div className="QueryElement">
                <p>Salary Range</p>
                <div className=" SalaryRange">
                    <input className="SalaryItem" value={queryBody.minSalary} type="number" onChange={QueryBodyUpdater("minSalary")}/>
                    <input className="SalaryItem" value={queryBody.maxSalary} type="number" onChange={QueryBodyUpdater("maxSalary")}/>
                </div>
            </div>
            <div className="QueryElement">
                <label>
                    <p>Office Location</p>
                    <input value={queryBody.location} type="text" onChange={QueryBodyUpdater("office")}/>
                </label>
            </div>
            <div className="QueryElement">
                <label>
                    <p>Skill</p>
                    <input value={queryBody.skill} type="text" onChange={QueryBodyUpdater("skill")}/>
                </label>
            </div>
        </div>
    );
}


export default EmployeeQuery;